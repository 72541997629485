import React from 'react';
import { ModelsIcon, MotionIcon, VDBIcon } from '../../modules/Icons';
import { Tooltip } from 'react-tooltip';

export const AssetType3DContainer = (props: any) => {
  const { collection, assetTypes } = props;
  let types = [];

  if (!assetTypes) {
    collection.collection_variants.forEach((cv) => {
      cv.optionables.forEach((opt) => {
        if (opt.full_type == 'Asset Type') {
          types.push(opt.value);
        }
      });
    });
  } else {
    types = assetTypes;
  }
  return (
    <div className="flex items-center gap-2 mr-2">
      {types.includes('3D Model') && (
        <div
          className="p-[7px] bg-[#1D2224] rounded-full"
          data-tooltip-id="typeTooltip"
          data-tooltip-content="3D Model"
        >
          <ModelsIcon className="w-[20px] h-[20px]" />
        </div>
      )}
      {types.includes('Animated') && (
        <div
          className="p-[7px] bg-[#1D2224] rounded-full"
          data-tooltip-id="typeTooltip"
          data-tooltip-content="Animated"
        >
          <MotionIcon className="w-[20px] h-[20px]" />
        </div>
      )}
      {types.includes('VDB') && (
        <div
          className="p-[7px] bg-[#1D2224] rounded-full"
          data-tooltip-id="typeTooltip"
          data-tooltip-content="VDB"
        >
          <VDBIcon className="w-[20px] h-[20px]" />
        </div>
      )}
      <Tooltip id={`typeTooltip`} opacity={1} style={{ backgroundColor: 'black' }} />
    </div>
  );
};
