import React from 'react';
import Link from 'next/link';
import handleViewport from 'react-in-viewport';
import { getAsUrl } from '../helpers/modules/infinitePaginationHelpers';
import { PaginationProps, LoadContentProps } from '../types/modules/InfinitePaginationTypes';
import { ThreeDots } from 'react-loader-spinner';

const Pagination: React.FC<PaginationProps> = (props) => {
  const { currentPage, totalPages, getContent } = props;
  const [page, setPage] = React.useState(currentPage);
  const [total, setTotal] = React.useState(totalPages);

  React.useEffect(() => {
    setPage(currentPage);
    setTotal(totalPages);
  });

  const LoadContent: React.FC<LoadContentProps> = (props) => {
    const { inViewport, forwardedRef } = props;
    if (inViewport) getContent(true);
    return <div ref={forwardedRef} className="ref" />;
  };

  const HandleLoading = handleViewport(LoadContent as any);
  const as: string = getAsUrl(props.loadAs, page + 1);

  if (currentPage >= total) return null;
  return (
    <div className="flex justify-center basis-full text-center col-span-full">
      <HandleLoading />
      <ThreeDots
        height="64"
        width="64"
        radius="6"
        color="#00A1E0"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
        wrapperClass="mx-auto"
      />
    </div>
  );
};

export default Pagination;
