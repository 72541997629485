import React from 'react';
import { AxiosResponse } from 'axios';
import { ENV } from '../../constants/environments';
import Collection from './Collection';
import InfinitePagination from '../../modules/InfinitePagination';
import { connect } from 'react-redux';
import {
  CollectionsComponentProps,
  CollectionsComponentState,
} from '../../types/pages/products/CollectionsTypes';


class CollectionsComponent extends React.Component<
  CollectionsComponentProps,
  CollectionsComponentState
> {
  constructor(props: CollectionsComponentProps) {
    super(props);

    this.state = {
      collections: [],
      currentPage: props.currentPage,
      totalPages: props.totalPages,
      collectionParams: props.collectionParams,
    };

    this.getCollections = this.getCollections.bind(this);
    this.setCollections = this.setCollections.bind(this);
    this.renderCollections = this.renderCollections.bind(this);

    this.apiUrl =
      (props.collectionParams as any)?.category_permalink == 'free-for-subscribers'
        ? `${ENV.api.baseURL}/collections/free-for-subscribers`
        : this.props.essentials
        ? `${ENV.api.baseURL}/products/essentials_catalog?library_id=${this.props.library_id}`
        : this.props.new
        ? `${ENV.api.baseURL}/products?library_id=${this.props.library_id}&sort_by=newest`
        : `${ENV.api.baseURL}/collections`;
  }

  public apiUrl: string = this.props.essentials ? `${ENV.api.baseURL}/products/essentials_catalog?library_id=${this.props.library_id}` : `${ENV.api.baseURL}/collections`;

  async getCollections(increment: boolean = false): Promise<void> {
    const { id, slug, query, dispatch } = this.props;
    const invalidId: boolean = Number.isNaN(+id);
    let pageNum: number = this.state.currentPage || 1;

    if (increment) pageNum++;

    let params = {
      ...this.props.collectionParams,
      desc: false,
      page: pageNum
    };

    if (query) {
      const searchParams = query === '_' ? {} : { search: query };
      Object.assign(params, searchParams);
    } else if (invalidId && slug !== 'all') {
      Object.assign(params, { category_slug: slug });
    } else if (!invalidId) {
      Object.assign(params, { category_id: id });
    }

    dispatch({
      type: 'GET_AUTHED_PRODUCTS',
      api: {
        method: 'GET',
        url: this.apiUrl,
        params,
        onSuccess: (res: AxiosResponse) => this.setCollections(res, pageNum, increment)
      }
    });
  }

  setCollections({ data }: AxiosResponse, pageNum: number, increment: boolean): void {
    const collections = increment ? this.state.collections.concat(data.data) : data.data;
    if (increment && this.props.onNextPageLoaded)
      this.props.onNextPageLoaded({ collections, pageNum });
    this.setState({
      collections: collections,
      currentPage: pageNum,
      totalPages: data.total_pages
    });
  }

  renderCollection(collection: any, index: number, keyPrefix: string = '') {
    return (
      <Collection
        key={`${keyPrefix}${index}`}
        id={collection.id}
        name={collection.name}
        slug={collection.slug}
        elements={collection.elements}
        new={collection.new}
        freeProduct={collection.free_product}
        varietyPack={collection.variety_pack}
        popular={collection.is_popular}
        owned={collection.owned}
        partiallyOwned={collection.partially_owned}
        media={collection.media}
        membershipTier={collection.membership_tier}
        library={collection.library}
        assetTypes={collection.asset_types}
        ownership={
          this.props?.collectionOwnership ? this.props?.collectionOwnership[collection.id] : null
        }
        contributor={collection?.contributor}
        sku={collection.sku}
      />
    )
  }

  renderCollections(): React.ReactNode[] {
    const collections: React.ReactNode[] = this.props.collections?.map((item, index) => this.renderCollection(item, index, 'ssr-collection-'));
    return (collections  || []).concat(this.state.collections.map((item, index) => this.renderCollection(item, index, 'collection-')));
  }

  componentDidUpdate(prevProps) {
    const { slug, currentPage, totalPages } = this.props;
    if (slug !== prevProps.slug) {
      this.setState({
        collections: [],
        currentPage,
        totalPages
      });
    }
    if (currentPage !== prevProps.currentPage) {
      this.setState({ currentPage });
    }
    if (currentPage !== prevProps.currentPage)
      this.setState({ currentPage });
    if (totalPages !== prevProps.totalPages)
      this.setState({ totalPages });
  }

  render() {
    return (
      <div className="w-full" data-testid="productsCollectionsContainer">
        <div className="responsive-product-grid md:min-w-[400px] gap-x-4 gap-y-6">
          {this.renderCollections()}

          {!this.props.hidePagination && (
            <InfinitePagination
              totalPages={this.state.totalPages}
              currentPage={this.state.currentPage}
              loadHref="/products/page/[page]"
              loadAs="/products/page/$"
              getContent={this.getCollections}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(CollectionsComponent);
