import Rollbar from 'rollbar';
import { ENV } from '../../constants/environments';

const enabled = process.env.NODE_ENV === 'production';

export class ErrorLogger {
  private errorService: Rollbar;

  static browser = new ErrorLogger(process.env.ROLLBAR_CLIENT_TOKEN);

  constructor(token: string) {
    this.errorService = new Rollbar({
      accessToken: token,
      captureUncaught: enabled,
      captureUnhandledRejections: enabled,
      enabled: true,
      verbose: true,
      payload: {
        environment: ENV.environment,
        client: {
          javascript: {
            code_version: process.env.NEXT_BUILD_ID
          }
        }
      }
    });
  }

  error(err, req?) {
    if (!enabled) return console.error(err);

    console.error('Reporting error to Rollbar...');
    this.errorService.error(err, req, (rollbarError, data) => {
      if (rollbarError) {
        console.error('Rollbar error reporting failed:');
        console.error(rollbarError);
        return;
      }
      console.log(`Reported error to Rollbar: https://rollbar.com/item/uuid/?uuid=${data.uuid}`);
    });
  }

  info(err, req?) {
    if (!enabled) return console.info(err);
    this.errorService.info(err, req);
  }

  warning(err, req?) {
    if (!enabled) return console.warn(err);
    this.errorService.warning(err, req);
  }
}

export const clientSideLogger = ErrorLogger.browser;
